export const projectsArchitectureData = [
  {
    _id: 13,
    title: "Family room in Saifi",
    sub_title: "Saifi Village",

    description:
      "We created a welcoming environment for various activities. From the sports corner to the TV area and dining space, every aspect is organized for hosting and relaxation, all while optimizing storage.",
    location: "– Beirut - Lebanon",
    images: [
      "/images/projects/architecture/saifi-room/saifi-room_01.jpg",
      "/images/projects/architecture/saifi-room/saifi-room_02.jpg",
      "/images/projects/architecture/saifi-room/saifi-room_03.jpg",
      "/images/projects/architecture/saifi-room/saifi-room_04.jpg",
      "/images/projects/architecture/saifi-room/saifi-room_05.jpg",
      "/images/projects/architecture/saifi-room/saifi-room_06.jpg",
    ],
  },
  {
    _id: 11,
    title: "Ashrafieh Rooftop",
    sub_title: "3D images by Jad Atallah",

    description:
      "Interior design of a duplex apartment with a garden terrace, utilizing 100 square meters of rooftop.",
    location: "Ashrafieh – Beirut - Lebanon",
    images: [
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_01.jpg",
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_02.jpg",
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_03.jpg",
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_04.jpg",
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_05.jpg",
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_06.jpg",
      "/images/projects/architecture/ashrafieh_rooftop/ashrafieh_rooftop_07.jpg",
    ],
  },
  {
    _id: 12,
    title: "Ashrafieh Building Renovation",
    sub_title: "3D images by Jad Atallah",

    description:
      "Renovation of a residential building constructed in 1961 and the addition of a rooftop apartment.",
    location: "Ashrafieh - Beirut - Lebanon",

    images: [
      "/images/projects/architecture/ashrafieh-building-renovation/ashrafieh-building-renovation_01.jpg",
      "/images/projects/architecture/ashrafieh-building-renovation/ashrafieh-building-renovation_02.jpg",
      "/images/projects/architecture/ashrafieh-building-renovation/ashrafieh-building-renovation_03.jpg",
      "/images/projects/architecture/ashrafieh-building-renovation/ashrafieh-building-renovation_04.jpg",
    ],
  },
  {
    _id: 15,
    title: "MV Project",
    sub_title: "Within the",
    team: "team",
    logo: "/logo/rab-logo.png",
    description:
      "As part of the rab architects team, Chirine contributed to on-site design solutions and site management. Photos by Paul Ghorra.",
    location: "Monteverde - Beirut",
    images: [
      "/images/projects/architecture/rab_monteverde/rab_monteverde_1.jpg",
      "/images/projects/architecture/rab_monteverde/rab_monteverde_2.jpg",
      "/images/projects/architecture/rab_monteverde/rab_monteverde_3.jpg",
      "/images/projects/architecture/rab_monteverde/rab_monteverde_4.jpg",
      "/images/projects/architecture/rab_monteverde/rab_monteverde_5.jpg",
    ],
  },
  {
    _id: 16,
    title: "DK Project",
    sub_title: "Within the",
    team: "team",
    logo: "/logo/rab-logo.png",
    description:
      "As part of the rab architects team, Chirine provided site follow-up and on-site design solutions. Photos by Julien Harfouch.",
    location: "Deir ElKamar - Lebanon",
    images: [
      "/images/projects/architecture/rab-dk/rab-dk_1.jpg",
      "/images/projects/architecture/rab-dk/rab-dk_2.jpg",
      "/images/projects/architecture/rab-dk/rab-dk_3.jpg",

      "/images/projects/architecture/rab-dk/rab-dk_6.jpg",
      "/images/projects/architecture/rab-dk/rab-dk_7.jpg",
    ],
    press: {
      press_image: "/logo/press-logo/archdaily.svg",
      press_article: "DK House / rab architects",
      press_name: "Arch Daily - Curated by Hana Abdel",
      press_path: "https://www.archdaily.com/954721/dk-house-rab-architects",
    },
  },
  {
    _id: 17,
    title: "NaDa Project",
    sub_title: "Within the",
    team: "team",
    logo: "/logo/rab-logo.png",
    description:
      "As part of the rab architects team, Chirine designed a small apartment and conducted site follow-up.",
    location: "Ashrafieh – Beirut - Lebanon",
    images: [
      "/images/projects/architecture/rab-nada/rab-nada_1.jpg",
      "/images/projects/architecture/rab-nada/rab-nada_2.jpg",
    ],
  },
];

export const projectsDesignData = [
  {
    _id: 111,
    title: "Dining Table",
    sub_title: "In Collaboration with PATRICK BOUSTANI –",
    finish: "Finished by YEW",
    description:
      "Chirine collaborated with Patrick Boustani to create a stunning dining table featuring a sailboat-inspired brass base and a walnut top with a hidden compartment.",
    location: "Ashrafieh – Beirut - Lebanon",
    images: [
      "/images/projects/furniture-design/brass-dining/organic-dining-table_1.jpg",
      "/images/projects/furniture-design/brass-dining/organic-dining-table_2.jpg",
      "/images/projects/furniture-design/brass-dining/organic-dining-table_3.jpg",
    ],
  },
  {
    _id: 112,
    title: "Wine Cellars",
    finish: "Finished by Chirine’s Atelier",
    description:
      "Custom fit-out within a private residence using solid untreated wood.",
    location: "Beirut - Lebanon",

    images: [
      "/images/projects/furniture-design/wine-cellars/wine-cellars_1.jpg",
      "/images/projects/furniture-design/wine-cellars/wine-cellars_2.jpg",
      "/images/projects/furniture-design/wine-cellars/wine-cellars_3.jpg",
      "/images/projects/furniture-design/wine-cellars/wine-cellars_4.jpg",
    ],
  },
  {
    _id: 113,
    title: "Side Table",
    finish: "Finished by YEW",
    description:
      "Easily demountable side table with a brushed stainless-steel frame and a solid Zebrano wood top, offering both functionality and style.",
    location: "Paris - France",
    images: [
      "/images/projects/furniture-design/side_table/side_table_1.jpg",
      "/images/projects/furniture-design/side_table/side_table_2.jpg",
      "/images/projects/furniture-design/side_table/side_table_3.jpg",
    ],
  },
  {
    _id: 114,
    title: "Foyer Bench",
    sub_title: "Within the",
    team: "team,",
    finish: "Finished by ARDECO Gallery",
    logo: "/logo/rab-logo.png",
    description:
      "As part of the rabarchitects team, Chirine designed a custom bench that also offered security for the stairwell.",
    location: "Beirut - Lebanon",

    images: [
      "/images/projects/furniture-design/rab-dk/entrance-bench_1.jpg",
      "/images/projects/furniture-design/rab-dk/entrance-bench_2.jpg",
      "/images/projects/furniture-design/rab-dk/entrance-bench_3.jpg",
      "/images/projects/furniture-design/rab-dk/entrance-bench_4.jpg",
      "/images/projects/furniture-design/rab-dk/entrance-bench_5.jpg",
    ],
  },
  {
    _id: 115,
    title: "Centerpiece",
    finish: "Finished by Chirine’s Atelier",
    description:
      "Hand-crafted convertible table with an acid-treated steel structure, varnished French Oak frame, and custom leather seating.",
    location: "Saifi – Beirut - Lebanon",
    images: [
      "/images/projects/furniture-design/coffee-dining/family-center_1.jpg",
      "/images/projects/furniture-design/coffee-dining/family-center_2.jpg",
      "/images/projects/furniture-design/coffee-dining/family-center_3.jpg",
    ],
  },
  {
    _id: 116,
    title: "Stainless Steel Dining",
    sub_title: "Within the",
    team: "team",
    logo: "/logo/rab-logo.png",
    description:
      "As part of the rabarchitects team, Chirine contributed to the design of a modern stainless steel serving station featuring ample storage for a cozy apartment.",
    location: "Beirut - Lebanon",
    images: [
      "/images/projects/furniture-design/rab-is/rab-is_1.jpg",
      "/images/projects/furniture-design/rab-is/rab-is_2.jpg",
      "/images/projects/furniture-design/rab-is/rab-is_3.jpg",
    ],
  },
  {
    _id: 117,
    title: "Stair Sculpture",
    finish: "Finished by Chirine’s Atelier",
    description:
      "Transforming a stairwell into a sculpture incorporating an origami-shaped stainless-steel bookcase that also functions as a security feature.",
    location: "Beitmery - Lebanon",
    images: [
      "/images/projects/furniture-design/staircase/stair-sculpture_1.jpg",
      "/images/projects/furniture-design/staircase/stair-sculpture_2.jpg",
    ],
  },
  {
    _id: 118,
    title: "Kids Bedroom",
    finish: "Finished by Chirine’s Atelier",
    description:
      "Personalized hand-painted wall art with motifs crafted from multiple layers of plywood.",
    location: "Ain Ar - Lebanon",

    images: [
      "/images/projects/furniture-design/kids-bedroom/kids-bedroom_1.jpg",
      "/images/projects/furniture-design/kids-bedroom/kids-bedroom_2.jpg",
      "/images/projects/furniture-design/kids-bedroom/kids-bedroom_3.jpg",
      "/images/projects/furniture-design/kids-bedroom/kids-bedroom_4.jpg",
      "/images/projects/furniture-design/kids-bedroom/kids-bedroom_5.jpg",
    ],
  },
  {
    _id: 119,
    title: "Headboard",
    finish: "Hand Made by Chirine",
    description:
      "Personalized hand-sewn wall art in the form of a foldable and shippable headboard design.",
    location: "Paris - France",
    images: [
      "/images/projects/furniture-design/headboard/headboard_1.jpg",
      "/images/projects/furniture-design/headboard/headboard_2.jpg",
      "/images/projects/furniture-design/headboard/headboard_3.jpg",
    ],
  },
];

export const projectsArtData = [
  {
    _id: 1111,
    italic: true,
    title_italic: "Minéral",
    date: "Beirut - 2014",
    description:
      "Chirine received critical acclaim for her first solo exhibition «Minéral», which expresses her inspiration from hiking and rock climbing in the spectacular Lebanese mountains. Art Critic Nélida Nassar wrote in April of 2016 : “... [ Ms Sayegh’s ] configurations are lyrical expressions of the particular qualities of light in the locations she chooses and remembers, atmospherically accurate and evocative of movement and strength…for like all artists who make a subject wholly their own, Sayegh puts the singular stamp of her style upon the place.”",
    canvas: [
      {
        id: "canvas1_1",
        title: "Faqra Lace",
        dimension: "100 x 140",
        media: "Acrylic on canvas",
        date: "2014",
        path: "/art/mineral/1",
        image_square:
          "/images/projects/art/mineral/mineral-dentelle-faqra-square.jpg",
        images: [
          "/images/projects/art/mineral/mineral-dentelle-faqra.jpg",
          "/images/projects/art/mineral/mineral-dentelle-faqra-insit.jpg",
        ],
      },
      {
        id: "canvas1_2",
        title: "Faqra",
        dimension: "160 x 120",
        media: "Acrylic on canvas",
        date: "2014",
        path: "/art/mineral/2",
        image_square: "/images/projects/art/mineral/mineral-faqra-square.jpg",
        images: [
          "/images/projects/art/mineral/mineral-faqra.jpg",
          "/images/projects/art/mineral/mineral-faqra-insit.jpg",
        ],
      },
      {
        id: "canvas1_3",
        title: "Sannine",
        dimension: "160 x 120",
        media: "Acrylic on canvas",
        date: "2014",
        path: "/art/mineral/3",
        image_square: "/images/projects/art/mineral/mineral-sannine-square.jpg",
        images: [
          "/images/projects/art/mineral/mineral-sannine.jpg",
          "/images/projects/art/mineral/mineral-sannine-insit.jpg",
        ],
      },
      {
        id: "canvas1_4",
        title: "Ribeze",
        dimension: "100 x 140",
        media: "Acrylic on canvas",
        date: "2014",
        path: "/art/mineral/4",
        image_square: "/images/projects/art/mineral/mineral-ribeze-square.jpg",
        images: [
          "/images/projects/art/mineral/mineral-ribeze.jpg",
          "/images/projects/art/mineral/mineral-ribeze-insit.jpg",
        ],
      },
    ],
  },
  {
    _id: 1112,
    title_italic: "Minéral - ",
    title: "Sea",
    date: "Beirut - 2022",
    description:
      "Capturing the contrast between water and rocks in her artwork, using a mix of fluid paint and thick strokes with a palette knife, inspired by the beaches of Batroun, Lebanon.",
    canvas: [
      {
        id: "canvas2_1",
        title: "Breathe",
        dimension: "61 x 45.7",
        media: "Acrylic on canvas paper",
        date: "2022",
        path: "/art/mineral-sea/1",
        image_square: "/images/projects/art/mineral-sea/breathe-square.jpg",
        images: [
          "/images/projects/art/mineral-sea/breathe.jpg",
          "/images/projects/art/mineral-sea/breathe-insit.jpg",
        ],
      },
      {
        id: "canvas2_2",
        title: "Love And Water",
        dimension: "61 x 45.7",
        media: "Acrylic on canvas paper",
        date: "2022",
        path: "/art/mineral-sea/2",
        image_square:
          "/images/projects/art/mineral-sea/love-and-water-square.jpg",
        images: [
          "/images/projects/art/mineral-sea/love-and-water.jpg",
          "/images/projects/art/mineral-sea/love-and-water-insit.jpg",
        ],
      },
      {
        id: "canvas2_3",
        title: "Hold On Tight",
        dimension: "61 x 45.7",
        media: "Acrylic on canvas paper",
        date: "2022",
        path: "/art/mineral-sea/3",
        image_square:
          "/images/projects/art/mineral-sea/hold-on-tight-square.jpg",
        images: [
          "/images/projects/art/mineral-sea/hold-on-tight.jpg",
          "/images/projects/art/mineral-sea/hold-on-tight-insit.jpg",
        ],
      },
      {
        id: "canvas2_4",
        title: "Walking On Corals",
        dimension: "61 x 45.7",
        media: "Acrylic on canvas paper",
        date: "2022",
        path: "/art/mineral-sea/4",
        image_square:
          "/images/projects/art/mineral-sea/walking-on-corals-square.jpg",
        images: [
          "/images/projects/art/mineral-sea/walking-on-corals.jpg",
          "/images/projects/art/mineral-sea/walking-on-corals-insit.jpg",
        ],
      },
    ],
  },
  {
    _id: 1113,
    title_italic: "Végétal - ",
    title: "Trees",
    date: "Beirut - 2010/2021",
    description:
      "Exploring the timeless subject of trees through acrylic paintings, representing deep forests, jungle scenes, evening strolls, and the iconic cedars of Lebanon.",
    canvas: [
      {
        id: "canvas3_1",
        title: "Deep Forest",
        dimension: "90 x 90",
        media: "Acrylic on canvas",
        date: "2010",
        path: "/art/vegetal-trees/1",
        image_square:
          "/images/projects/art/vegetal-trees/deep-forest-square.jpg",
        images: [
          "/images/projects/art/vegetal-trees/deep-forest.jpg",
          "/images/projects/art/vegetal-trees/deep-forest-insit.jpg",
        ],
      },
      {
        id: "canvas3_2",
        title: "Jungle Fever",
        dimension: "200 x 110",
        media: "Acrylic on canvas",
        date: "2020",
        path: "/art/vegetal-trees/2",
        image_square:
          "/images/projects/art/vegetal-trees/jungle-fever-square.jpg",
        images: [
          "/images/projects/art/vegetal-trees/jungle-fever.jpg",
          "/images/projects/art/vegetal-trees/jungle-fever-insit.jpg",
        ],
      },
      {
        id: "canvas3_3",
        title: "Evening Sroll",
        dimension: "97 x 147",
        media: "Acrylic on canvas",
        date: "2021",
        path: "/art/vegetal-trees/3",
        image_square:
          "/images/projects/art/vegetal-trees/evening-stroll-square.jpg",
        images: [
          "/images/projects/art/vegetal-trees/evening-stroll.jpg",
          "/images/projects/art/vegetal-trees/evening-stroll-insit.jpg",
        ],
      },
      {
        id: "canvas3_4",
        title: "Our Cedars",
        dimension: "Diptych: 2 x 140 x 100",
        media: "Acrylic on canvas",
        date: "2022",
        path: "/art/vegetal-trees/4",
        image_square:
          "/images/projects/art/vegetal-trees/our-cedars-square.jpg",
        images: [
          "/images/projects/art/vegetal-trees/our-cedars.jpg",
          "/images/projects/art/vegetal-trees/our-cedars-insit.jpg",
        ],
      },
    ],
  },
  {
    _id: 1114,
    title_italic: "Végétal - ",
    title: "Watercolors",

    date: "Beirut - 2020/2021",
    description:
      "Watercolor sketches serve as research, sometimes becoming the main pieces themselves, depicting the beauty of nature with vibrant and fluid brushstrokes.",
    canvas: [
      {
        id: "canvas4_1",
        title: "Deep Into Summer",
        dimension: "76 x 56",
        media: "Watercolor on cotton paper",
        date: "2020",
        path: "/art/vegetal-watercolors/1",
        image_square:
          "/images/projects/art/vegetal-watercolors/deep-into-summer-square.jpg",
        images: [
          "/images/projects/art/vegetal-watercolors/deep-into-summer.jpg",
          "/images/projects/art/vegetal-watercolors/deep-into-summer-insit.jpg",
        ],
      },
      {
        id: "canvas4_2",
        title: "Floral Madness",
        dimension: "76 x 56",
        media: "Watercolor on cotton paper",
        date: "2021",
        path: "/art/vegetal-watercolors/2",
        image_square:
          "/images/projects/art/vegetal-watercolors/floral-madness-square.jpg",
        images: [
          "/images/projects/art/vegetal-watercolors/floral-madness.jpg",
          "/images/projects/art/vegetal-watercolors/floral-madness-insit.jpg",
        ],
      },
      {
        id: "canvas4_3",
        title: "Wildflowers",
        dimension: "76 x 56",
        media: "Watercolor on cotton paper",
        date: "2020",
        path: "/art/vegetal-watercolors/3",
        image_square:
          "/images/projects/art/vegetal-watercolors/wildflowers-square.jpg",
        images: [
          "/images/projects/art/vegetal-watercolors/wildflowers.jpg",
          "/images/projects/art/vegetal-watercolors/wildflowers-insit.jpg",
        ],
      },
      {
        id: "canvas4_4",
        title: "Summer Nights ",
        dimension: "76 x 56",
        media: "Watercolor on cotton paper",
        date: "2020",
        path: "/art/vegetal-watercolors/4",
        image_square:
          "/images/projects/art/vegetal-watercolors/leaves-square.jpg",
        images: [
          "/images/projects/art/vegetal-watercolors/leaves.jpg",
          "/images/projects/art/vegetal-watercolors/leaves-insit.jpg",
        ],
      },
    ],
  },
  {
    _id: 1115,
    title_italic: "Végétal - ",
    title: "Gold Series",
    date: "Beirut - 2021/2023",
    description:
      "Combining the fluidity of watercolor with the brilliance of gold leaf to create captivating artwork, capturing the harmonious relationship between the two elements.",
    canvas: [
      {
        id: "canvas5_1",
        title: "Forest Moon",
        dimension: "29.7 x 42",
        media: "Watercolor on cotton paper with gold leaf",
        date: "2021",
        path: "/art/vegetal-gold-series/1",
        image_square:
          "/images/projects/art/vegetal-gold-series/forest-moon-square.jpg",
        images: [
          "/images/projects/art/vegetal-gold-series/forest-moon.jpg",
          "/images/projects/art/vegetal-gold-series/forest-moon-insit.jpg",
        ],
      },
      {
        id: "canvas5_2",
        title: "H2O",
        dimension: "42 x 29.7",
        media: "Watercolor on cotton paper with gold leaf",
        date: "2021",
        path: "/art/vegetal-gold-series/2",
        image_square: "/images/projects/art/vegetal-gold-series/h2o-square.jpg",
        images: [
          "/images/projects/art/vegetal-gold-series/h2o.jpg",
          "/images/projects/art/vegetal-gold-series/h2o-insit.jpg",
        ],
      },
      {
        id: "canvas5_3",
        title: "Roses",
        dimension: "29.7 x 42",
        media: "Watercolor on cotton paper with gold leaf",
        date: "2021",
        path: "/art/vegetal-gold-series/3",
        image_square:
          "/images/projects/art/vegetal-gold-series/roses-square.jpg",
        images: [
          "/images/projects/art/vegetal-gold-series/roses.jpg",
          "/images/projects/art/vegetal-gold-series/roses-insit.jpg",
        ],
      },
      {
        id: "canvas5_4",
        title: "Landscape",
        dimension: "46.5 x 30.5",
        media: "Watercolor on cotton paper with gold leaf",
        date: "2021",
        path: "/art/vegetal-gold-series/4",
        image_square:
          "/images/projects/art/vegetal-gold-series/landscape-square.jpg",
        images: [
          "/images/projects/art/vegetal-gold-series/landscape.jpg",
          "/images/projects/art/vegetal-gold-series/landscape-insit.jpg",
        ],
      },
    ],
  },
  {
    _id: 1116,
    title_italic: "Végétal - ",
    title: "Secret Garden",
    date: "Beirut - 2023",
    description:
      "The connection between creatures and nature, with ethereal watercolor and acrylic paintings evoking a sense of mystery and transformation.",
    canvas: [
      {
        id: "canvas6_1",
        title: "Woman Appears",
        dimension: "50 x 86",
        media: "Acrylic on canvas",
        date: "2023",
        path: "/art/vegetal-secret-garden/1",
        image_square:
          "/images/projects/art/vegetal-secret-garden/woman-appears-square-1.jpg",
        images: [
          "/images/projects/art/vegetal-secret-garden/woman-appears-1.jpg",
          "/images/projects/art/vegetal-secret-garden/woman-appears-1-insit.jpg",
        ],
      },
      {
        id: "canvas6_2",
        title: "Woman Disappears",
        dimension: "100 x 113",
        media: "Acrylic on canvas",
        date: "2023",
        path: "/art/vegetal-secret-garden/2",
        image_square:
          "/images/projects/art/vegetal-secret-garden/woman-disappears-square-1.jpg",
        images: [
          "/images/projects/art/vegetal-secret-garden/woman-disappears-1.jpg",
          "/images/projects/art/vegetal-secret-garden/woman-disappears-1-insit.jpg",
        ],
      },
      {
        id: "canvas6_3",
        title: "Woman Appears",
        dimension: "30.3 x 40.4",
        media: "Watercolor on cotton paper with gold leaf",
        date: "2023",
        path: "/art/vegetal-secret-garden/3",
        image_square:
          "/images/projects/art/vegetal-secret-garden/woman-appears-square-2.jpg",
        images: [
          "/images/projects/art/vegetal-secret-garden/woman-appears-2.jpg",
          "/images/projects/art/vegetal-secret-garden/woman-appears-2-insit.jpg",
        ],
      },
      {
        id: "canvas6_4",
        title: "Woman Disappears",
        dimension: "30.3 x 40.4",
        media: "Watercolor on cotton paper with gold leaf",
        date: "2023",
        path: "/art/vegetal-secret-garden/4",
        image_square:
          "/images/projects/art/vegetal-secret-garden/woman-disappears-square-2.jpg",
        images: [
          "/images/projects/art/vegetal-secret-garden/woman-disappears-2.jpg",
          "/images/projects/art/vegetal-secret-garden/woman-disappears-2-insit.jpg",
        ],
      },
    ],
  },
  {
    _id: 1117,
    title_italic: "Animal - ",
    title: "Sketches",

    date: "Beirut - 2020",
    description:
      "During the COVID-19 lockdown, Chirine created expressive ink sketches of pets, showcasing their individuality and charm.",
    canvas: [
      {
        id: "canvas7_1",
        title: "Andy",
        dimension: "29.7 x 21",
        media: "Ink on paper",
        date: "2020",
        path: "/art/animal-sketches/1",
        image_square: "/images/projects/art/animal-sketches/andy-square.jpg",
        images: [
          "/images/projects/art/animal-sketches/andy.jpg",
          "/images/projects/art/animal-sketches/andy-insit.jpg",
        ],
      },
      {
        id: "canvas7_2",
        title: "Bubble",
        dimension: "21 x 29.7",
        media: "Ink on paper",
        date: "2020",
        path: "/art/animal-sketches/2",
        image_square: "/images/projects/art/animal-sketches/bubble-square.jpg",
        images: [
          "/images/projects/art/animal-sketches/bubble.jpg",
          "/images/projects/art/animal-sketches/bubble-insit.jpg",
        ],
      },
      {
        id: "canvas7_3",
        title: "Duke",
        dimension: "29.7 x 21",
        media: "Ink on paper",
        date: "2020",
        path: "/art/animal-sketches/3",
        image_square: "/images/projects/art/animal-sketches/duke-square.jpg",
        images: [
          "/images/projects/art/animal-sketches/duke.jpg",
          "/images/projects/art/animal-sketches/duke-insit.jpg",
        ],
      },
      {
        id: "canvas7_4",
        title: "Rocky",
        dimension: "21 x 29.7",
        media: "Ink on paper",
        date: "2020",
        path: "/art/animal-sketches/4",
        image_square: "/images/projects/art/animal-sketches/rocky-square.jpg",
        images: [
          "/images/projects/art/animal-sketches/rocky.jpg",
          "/images/projects/art/animal-sketches/rocky-insit.jpg",
        ],
      },
    ],
  },
];
