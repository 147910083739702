export const contactData = {
  phone: "+961 (3) 726 498",
  tel: "+9613726498",
  email: "sayeghchirine@gmail.com",

  insta_1: "chirine_sayegh_architect",
  insta_1_url:
    "https://instagram.com/chirine_sayegh_architect?igshid=MzRlODBiNWFlZA==",
  insta_2: "chirine_sayegh_art",
  insta_2_url:
    "https://instagram.com/chirine_sayegh_art?igshid=MzRlODBiNWFlZA==",
  linkedIn_url: "http://linkedin.com/in/chirine-sayegh-881598",
};
