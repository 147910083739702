export const navLinks = [
  {
    _id: 1,
    title: "Home",
    path: "/",
  },
  {
    _id: 2,
    title: "Bio",
    path: "/bio",
  },
  {
    _id: 3,
    title: "Architecture",
    path: "/architecture",
  },
  {
    _id: 4,
    title: "furniture design",
    path: "/furniture-design",
  },
  {
    _id: 5,
    title: "art",
    path: "/art",
    classname: true,
    icon: true,
    icon_url: "/icons/flower.svg",
  },
  {
    _id: 6,
    title: "exhibitions & press",
    path: "/exhibitions-press",
  },
  {
    _id: 7,
    title: "contact",
    path: "/contact",
  },
];
