import React from "react";
import BioSection from "../components/BioSection/BioSection";

const Bio = () => {
  return (
    <>
      <BioSection />
    </>
  );
};

export default Bio;
