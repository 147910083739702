export const exhibitionData = [
  {
    id: "E2022",
    year: "2022",
    exhibitions: [
      {
        title: "The Gold Series",
        date: "Sold all year long",
        place: "The Edit - AlSerkal Avenue - Dubai",
        location: "",
      },
      {
        title: "Pop of Hope",
        description: "Illustrations were printed on notebooks",
        date: "Sold in a Christmas Exhibition",
        place: "Espace Matisse - Ashrafieh - Lebanon ",
        press: [
          {
            press_name: "OLJ / Par C.H., 07 Décembre 2022",
            press_article:
              "Pop of Hope, une pincée d’espoir et de poudre de talents libanais",
            press_image: "/logo/press-logo/olj.png",
            press_path:
              "https://www.lorientlejour.com/article/1320649/pop-of-hope-une-pincee-despoir-et-de-poudre-dange.html",
          },
        ],
      },
      {
        title: "L’Expo du Cœur",
        date: "June 3 - 6",
        place: "ALBA, Sin el Fil",
        press: [
          {
            press_name: "OLJ / Danny MALLAT, 03 Juin 2022 ",
            press_image: "/logo/press-logo/olj.png",
            press_article:
              "A l'Alba, les artistes d’aujourd’hui viennent au secours de ceux de demain",
            press_path:
              "https://www.lorientlejour.com/article/1301568/quand-les-artistes-daujourdhui-viennent-au-secours-de-ceux-de-demain.html",
          },
        ],
      },
      {
        title: "Haut en Bleu",
        date: "June 22 - November 11 ",
        place: "Les Plumes Galerie, Ashrafieh",
        press: [
          {
            press_image: "/logo/press-logo/agendaculturel.png",
            press_article: "Creative Exhibition: Haut en Bleu",
            press_name: "Agenda Culturel",
            press_path:
              "https://www.agendaculturel.com/event/creative-exhibition-haut-en-bleu",
          },
        ],
      },
      {
        title: "Art Defined",
        date: "July 9 - 27",
        place: "Curated by The Gallerist at Rebirth Beirut, Gemmayzeh",
        press: [
          {
            press_image: "/logo/press-logo/961.jpg",

            press_name: "The 961, July 8 2022",
            press_article:
              "Contemporary Art Exhibition Taking Place In The Heart Of Beirut",
            press_path:
              "https://www.the961.com/contemporary-art-exhibition-taking-place-in-beirut/ ",
          },
        ],
      },
    ],
  },
  {
    id: "E2019",
    year: "2019",
    exhibitions: [
      {
        title: "OFF / SITE",
        sub_title: "Christmas exhibition at Galerie Rochane, Saifi.",

        date: "September 17 - 24",
        place: "Hotel Le Grey Beirut, Lebanon during Beirut Art Fair",
        press: [
          {
            press_article: "Off / Site Beirut Art Fair ~ Beirut Art Week",
            press_image: "/logo/press-logo/lebtivity.jpg",
            press_name: "Lebtivity",
            press_path:
              "https://lebtivity.com/event/off-site-beirut-art-fair-beirut-art-week",
          },
        ],
      },
    ],
  },
  {
    id: "E2016",
    year: "2016",
    exhibitions: [
      {
        title: "Minéral",
        sub_title: "Christmas Exhibition at Galerie Rochane, Saifi.",
        date: "April 21 - 27",
        place: "ALBA, Sin el Fil",

        press: [
          {
            press_image: "/logo/press-logo/artetculture.jpg",
            press_name: "Art & Culture Today / Nélida Nassar, 24 April 2016",
            press_article:
              "The Paintings of Chirine Sayegh Make a Mark and Reconfigure the Lebanese Landscape",
            press_path:
              "https://artandculturetoday.wordpress.com/art/painting/the-paintings-of-chirine-sayegh-make-a-mark-and-reconfigure-the-lebanese-landscape/",
          },
          {
            press_image: "/logo/press-logo/alba.jpg",

            press_article: "'Minéral', Exposition de Chirine Sayegh",
            press_name:
              "Academie Libanaise des Beaux Arts (ALBA), Jeudi 21 Avril 2016 ",
            press_path:
              "https://alba.edu.lb/french/Min%C3%A9ral,-exposition-de-Chirine-Sayegh",
          },
          {
            press_image: "/logo/press-logo/olj.png",
            press_name: "OLJ / Par Colette KHALAF, 20 avril 2016",
            press_article: "« Ma montagne est un être vivant »",
            press_path:
              "https://www.lorientlejour.com/article/981949/-ma-montagne-est-un-etre-vivant-.html",
          },
        ],
      },
    ],
  },
  {
    id: "E2010",
    year: "2010",
    exhibitions: [
      {
        title: "Artmonies",
        sub_title: "Christmas exhibition at Galerie Rochane, Saifi.",

        date: "November 25 - 28",
        place: "Crypts of St. Joseph Church,  Ashrafieh",

        press: [
          {
            press_image: "/logo/press-logo/olj.png",
            press_name: "OLJ / le 26 novembre 2010",
            press_article: "« Artmonies » diverses à la crypte Saint-Joseph",
            press_path:
              "https://www.lorientlejour.com/article/679650/%253C%253C%2BArtmonies%2B%253E%253E_diverses_a_la_crypte_Saint-Joseph.html",
          },
        ],
      },
    ],
  },
];
