export const introData = [
  {
    _id: "1_architecture",
    title: "architecture",
    path: "/architecture",
    image: "/images/intro-images/architecture-section.jpg",
    icon: "/logo/logo-icon.svg",
    alt: "architecture image",
    text: "At the heart of our environmentally sensitive construction projects lies a focus on sustainability, transparent and effective communication, and the development of versatile spaces.",
  },
  {
    _id: "2_furnituredesign",
    title: "furniture design",
    path: "/furniture-design",
    image: "/images/intro-images/design-section.jpg",
    icon: "/logo/logo-icon.svg",
    text: "With Chirine’s background as an architect and artist, responsible material use and the combination of functional and aesthetic design is our priority. Our commitment to quality and attention to detail ensures careful execution of every aspect of the design.",
  },
  {
    _id: "3_art",
    title: "art",
    path: "/art",
    icon_flower: true,
    image: "/images/intro-images/art-section.jpg",
    icon: "/icons/flower.svg",
    text: "Chirine’s body of work is informed by nature; being a continuous investigation of organic shapes, texture, contrast, and motion. Working in the world of architecture and design inspired Chirine to produce paintings and sculptures that create immersive environments where viewers are always reminded of the endangered relationship that we all have with the world.",
  },
];
